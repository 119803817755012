import {IApiResponse, IData} from "@/types"
import ApiService from "@/services/api_service"
import store from "@/store"

export default {

  getTickets(options: IData = {}, cancelTokenId?: string): Promise<IData[]> {
    return new Promise((resolve, reject) => {
      const actionName = 'get_all_tickets'

      const params: IData = {
        action: actionName,
        ...options,
      }

      ApiService.request({
        method: "get",
        params,
      }, cancelTokenId).then((data) => {
        console.log('SeekersService getTickets success', data)
        data?.xdata?.tickets ? resolve(data.xdata.tickets) : reject(data)
      }).catch((data) => {
        console.log('SeekersService getTickets error', data)
        reject(data)
      })
    })
  },

  getSeekerUsersItems(options: IData = {}, cancelTokenId?: string): Promise<IData[]> {
    return new Promise((resolve, reject) => {

      const actionName = 'get_user_seeker' //'get_all_users'

      const params: IData = {
        action: actionName,
        ...options,
      }

      ApiService.request({
        method: "get",
        params,
      }, cancelTokenId).then((data) => {
        const noOtherUsers = !!data?.xdata?.no_other_users
        store.commit('setNoOtherUsers', noOtherUsers)
        const response = data?.xdata?.users
        console.log('SeekersService getSeekerUsersItems success', data, response)
        // store.commit(screenName + '/appendNotes', notesResponse.items)
        // store.commit(screenName + '/set_notesOffset', notesResponse.data_offset)
        // store.commit(screenName + '/set_isMoreNotes', !!notesResponse.show_next)
        resolve(response)
      }).catch((data) => {
        console.log('SeekersService getSeekerUsersItems error', data)
        reject(data)
      })
    })
  },

  getSeekerProjectsItems(options: IData={}, cancelTokenId?: string): Promise<IData[]> {
    return new Promise((resolve, reject) => {

      const actionName = 'get_all_projects' //'get_all_users'

      const params: IData = {
        action: actionName,
        ...options,
      }

      ApiService.request({
        method: "get",
        params,
      }, cancelTokenId).then((data) => {
        const response = data?.xdata?.projects
        console.log('SeekersService getSeekerProjectsItems success', data, response)
        // store.commit(screenName + '/appendNotes', notesResponse.items)
        // store.commit(screenName + '/set_notesOffset', notesResponse.data_offset)
        // store.commit(screenName + '/set_isMoreNotes', !!notesResponse.show_next)
        response.forEach((i)=>{
          if (i.id == undefined) {
            i.id = i.key;
          }
        })
        resolve(response)
      }).catch((data) => {
        console.log('SeekersService getSeekerProjectsItems error', data)
        reject(data)
      })
    })
  }

/*
  getSeekerTagsItems(options: IData = {}, cancelTokenId?: string): Promise<IData[]> {
    return new Promise((resolve, reject) => {

      const actionName = 'get_all_tags'

      const params: IData = {
        action: actionName,
        ...options,
      }

      ApiService.request({
        method: "get",
        params,
      }, cancelTokenId).then((data) => {
        const response = data?.xdata?.tags
        console.log('SeekersService getSeekerTagsItems success', data, response)
        // store.commit(screenName + '/appendNotes', notesResponse.items)
        // store.commit(screenName + '/set_notesOffset', notesResponse.data_offset)
        // store.commit(screenName + '/set_isMoreNotes', !!notesResponse.show_next)
        resolve(response)
      }).catch((data) => {
        console.log('SeekersService getSeekerTagsItems error', data)
        reject(data)
      })
    })
  },
*/
}
