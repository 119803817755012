import { TokenService } from "@/services/storage_service";
import ApiService from "@/services/api_service";
import qs from 'qs';
import { AxiosPromise, AxiosResponse } from "axios";
import store from "@/store"

class AuthenticationError extends Error {

  errorCode: number
  messages: string
  redirect: string | null
  redirectDelay: number

  constructor(errorCode: number, message: string) {
    super(message)
    this.name = this.constructor.name
    this.message = message
    this.errorCode = errorCode
    this.messages = ""
    this.redirect = null
    this.redirectDelay = 3000
  }
}

const getGlobalAuthRequestParams = () => {
  const globalProps = {}
  const isDebugMode = window['disarea'] && window['disarea']['isDebugMode']
  console.log('getGlobalAuthRequestParams auth_service isDebugMode', isDebugMode)

  if (isDebugMode) {
    const serverParam = store.getters['dev/serverParam']
    console.log('getGlobalAuthRequestParams auth_service serverParam', serverParam)
    if (serverParam) {
      Object.assign(globalProps, {
        serverParam
      })
    }
  }
  return globalProps
}

const AuthService = {
  preload_login: function (): AxiosPromise {
    const params = {
      location_pathname: window.location.pathname,
      location_search: window.location.search,
      location_hash: window.location.hash
    }

    return ApiService._axios({
      params: Object.assign(params, getGlobalAuthRequestParams()),
      url: "/preload_login.php"
    });
  },


  login: function (login: string, password: string, domain: string, remember: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      ApiService._axios({
        url: '/authorize.php',
        headers: {
          //"Content-Type": "application/x-www-form-urlencoded"
        },
        params: Object.assign({}, getGlobalAuthRequestParams()),
        data: qs.stringify({ "login": login, "password": password, "domain": domain, "rm": remember ? '1' : '0' }),
        method: "POST"
      }).then(response => {
        //TokenService.saveRefreshToken(response.data.refreshToken);
        TokenService.saveToken(response.data.xdata.Authorization);
        ApiService.setHeader()
        ApiService.mount401Interceptor();
        resolve(response.data.xdata);
      }).catch(error => {
        const e1 = new AuthenticationError(error.errorCode, error.message);
        if (error.response.data.message) {
          e1.messages = Object.values(error.response.data.message).join(", ");
          e1.redirect = error.response.data.redirect || null;
          e1.redirectDelay = error.response.data.redirect_delay || null;
        }
        reject(e1);
      })
    });
  },

  forgotPassword: function (login: string): Promise<any> {
    return new Promise((resolve, reject) => {
      ApiService._axios({
        // url: '/forgot_pass.php',
        // headers: {
        //   //"Content-Type": "application/x-www-form-urlencoded"
        // },
        params: Object.assign({ action: "forgot_pass", "email": login, submit_login: 1 }, getGlobalAuthRequestParams()),
        method: "POST"
      }).then(response => {
        if (response.data.isError || response.data.isError == undefined) {
          const data = Object.assign({
            message: ApiService.getDefaultErrorMessage(),
            xdata: {}
          }, response.data)
          reject(data);
        } else {
          resolve(response.data);
        }
      }).catch(error => {
        reject(error);
      })
    });
  },

  resetPassword: function (login: string, hasCode: string, newPass: string, newUser: string): Promise<any> {
    return new Promise((resolve, reject) => {
      ApiService._axios({
        url: '/reset_pass.php',
        headers: {
          //"Content-Type": "application/x-www-form-urlencoded"
        },
        params: Object.assign({}, getGlobalAuthRequestParams()),
        data: qs.stringify({ email: login, [newUser === '1' ? 'nkey' : 'key']: hasCode, 'password': newPass }),
        method: "POST"
      }).then(response => {
        if (response.data.isError || response.data.isError == undefined) {
          const data = Object.assign({
            message: ApiService.getDefaultErrorMessage(),
            xdata: {}
          }, response.data)
          reject(data);
        } else {
          resolve(response.data);
        }
      }).catch(error => {
        reject(error);
      })
    });
  },

  checkResetLink: function (login: string, hasCode: string): Promise<any> {
    return new Promise((resolve, reject) => {
      ApiService._axios({
        url: '/reset_pass.php',
        headers: {
          //"Content-Type": "application/x-www-form-urlencoded"
        },
        params: Object.assign({}, getGlobalAuthRequestParams()),
        data: qs.stringify({ "email": login, "key": hasCode, "check_link": 1 }),
        method: "POST"
      }).then(response => {
        if (response.data.isError || response.data.isError == undefined) {
          const data = Object.assign({
            message: ApiService.getDefaultErrorMessage(),
            xdata: {}
          }, response.data)
          reject(data);
        } else {
          resolve(response.data.xdata);
        }
      }).catch(error => {
        reject(error);
      })
    });
  },

  refreshToken: function (): Promise<string> {
    return new Promise((resolve, reject) => {
      ApiService._axios({
        url: '/api/refresh_token.php',
        params: Object.assign({}, getGlobalAuthRequestParams()),
        data: { refreshToken: TokenService.getRefreshToken() },
        method: 'POST'
      }).then(response => {
        TokenService.saveRefreshToken(response.data.refreshToken);
        TokenService.saveToken(response.data.token);
        resolve(response.data.token);
      }).catch(error => {
        reject(error);
      })
    });
  },

  logout: function (): Promise<void> {
    TokenService.removeToken()
    TokenService.removeRefreshToken()
    ApiService.removeHeader()
    ApiService.unmount401Interceptor();
    return new Promise((resolve, reject) => {
      ApiService._axios({
        params: Object.assign({}, getGlobalAuthRequestParams()),
        url: "/logout.php"
      }).then(response => {
        resolve()
      }).catch(error => {
        reject()
      })
    })
  }
}

export default AuthService;

export { AuthenticationError }
