import {createStore} from 'vuex'
import auth from './modules/auth'
import people from "@/store/modules/people"
import settings from "@/store/modules/settings"
import dashboard from "@/store/modules/dashboard"
import listview from "@/store/modules/listview"
import ticket from "@/store/modules/ticket"
import dev from "@/store/modules/dev"
import board from "@/store/modules/board"
import ticketsfilters from "@/store/modules/ticketsfilters"
import {IConfirm, IData, INotification, ISeeker, ITicketTag, ITicketTag2, ITopMessage} from "@/types"
import _uniqueId from "lodash/uniqueId"
import autorefresh from "@/store/modules/autorefresh"
import automation from "@/store/modules/automation"
import project_info from "@/store/modules/project_info"
import project_team from "@/store/modules/project_team"
import ticket_designer from "@/store/modules/ticket_designer"
import workflow_designer from "@/store/modules/workflow_designer"
import report_general from "@/store/modules/report_general"
import account from "@/store/modules/account"

export interface RootState {
  ver: string
  theme: 'auto' | 'light' | 'dark'
  lastUpdated: string
  highlightLastUpdated: boolean
  demoDomain: boolean
  trialDomain: boolean
  trialLeftTime: string
  trialTimeProgress: number
  navbarOpened: boolean
  companyURL: undefined | string
  currentProject: undefined | IData
  currentScreen: null | 'board' | 'dashboard' | 'listview' | 'ticket' | 'people'
  projects: IData[]
  projectsSeekerActiveFilter: null | '0' | '1'
  usersForFilter: IData[]
  tags: ITicketTag[] | ITicketTag2[]
  tagsByNames: {[key: string]: ITicketTag} | null
  projectTab: string
  settingsTab: string
  config: IData
  notifications: INotification[]
  confirms: IConfirm[]
  loaders: IData[]
  topMessages: ITopMessage[]
  storageLimitReached: boolean
  usersLimitReached: boolean
  forceAllowUnload: boolean
  seekers: ISeeker[]
  needShowSmileFeedback: boolean
  noOtherUsers: boolean
  noTags: boolean
}

export default createStore({
  strict: process.env.NODE_ENV !== 'production',

  state: {
    ver: 'v2.0.1',
    theme: 'auto',
    lastUpdated: '',
    highlightLastUpdated: false,
    demoDomain: false,
    trialDomain: false,
    trialLeftTime: '',
    trialTimeProgress: 0,
    navbarOpened: true,
    companyURL: undefined,
    currentProject: undefined,
    currentScreen: 'board',
    projects: [],
    projectsSeekerActiveFilter: '1',
    usersForFilter: [],
    tags: [],
    tagsByNames: null,
    projectTab: 'board',
    settingsTab: 'ProjectInfo',
    config: {},
    notifications: [],
    confirms: [],
    loaders: [],
    topMessages: [],
    storageLimitReached: false,
    usersLimitReached: false,
    forceAllowUnload: false,
    seekers: [],
    needShowSmileFeedback: false,
    noOtherUsers: false,
    noTags: false,
  },

  mutations: {
    changeTheme(state, theme: 'auto' | 'light' | 'dark') {
      state.theme = ['auto', 'light', 'dark'].includes(theme) ? theme : 'auto'
    },

    updateTitle(state, value) {
      document.title = value.pageTitle
    },

    setLastUpdated(state, value) {
      state.lastUpdated = value
    },

    setHighlightLastUpdated(state, value) {
      state.highlightLastUpdated = value
    },

    setDemoDomain(state, value) {
      state.demoDomain = value
    },

    setTrialDomain(state, value) {
      state.trialDomain = value
    },

    setTrialLeftTime(state, value) {
      state.trialLeftTime = value
    },

    setTrialTimeProgress(state, value) {
      state.trialTimeProgress = value
    },

    toggleNavbar(state, isOpen?: boolean) {
      console.log('toggleNavbar', isOpen, typeof isOpen === 'undefined')
      if (typeof isOpen === 'undefined') {
        isOpen = !state.navbarOpened
      }
      console.log('toggleNavbar isOpen', isOpen)
      state.navbarOpened = isOpen
    },

    updateCompanyURL(state, {companyURL}) {
      state.companyURL = companyURL
    },

    updateProject(state, {project}) {
      console.log('change current project in state', project);
      state.currentProject = project
    },

    updateProjects(state, projects) {
      state.projects = projects;
    },

    setProjectsSeekerActiveFilter(state, value) {
      state.projectsSeekerActiveFilter = value
    },

    set_usersForFilter(state, users: IData[] | null | undefined) {
      if (Array.isArray(users)) {
        state.usersForFilter = users;
      }
    },
    setTags(state, tags) {
      state.tags = tags;

      state.tagsByNames = {}
      tags.forEach(tag => {
        if (tag.key && state.tagsByNames) {
          state.tagsByNames[tag.key] = tag
        }
      })

    },
    setProjectTab(state, tab: string) {
      state.projectTab = tab
    },
    setSettingsTab(state, tab: string) {
      state.settingsTab = tab
    },
    setConfig(state, configData) {
      Object.assign(state.config, configData);
    },
    addNotification(state, notification: INotification) {
      state.notifications.push(notification)
    },

    removeNotification(state, id: string) {
      state.notifications = state.notifications.filter(item => item.id !== id)
    },

    addConfirm(state, confirm: IConfirm) {
      state.confirms.push(confirm)
    },

    removeConfirm(state, id: string) {
      state.confirms = state.confirms.filter(item => item.id !== id)
    },

    setLoaders(state, data: IData[]) {
      state.loaders= data
    },

    addLoader(state, data: IData) {
      state.loaders.push(data)
    },

    removeLoader(state, attrs: IData) {
      state.loaders = state.loaders.filter((block) => {
        console.log('removeLoader', block, Object.keys(attrs).find((attrName) => block[attrName] !== attrs[attrName]))
        return Object.keys(attrs).find((attrName) => block[attrName] !== attrs[attrName])

        // return _.isUndefined(_.find(attrs, function (attrValue, attrName) {
        //   return block[attrName] !== attrValue;
        // }))

      })
      console.log('removeLoader loaders', state.loaders, JSON.stringify(state.loaders))
    },

    setTopMessages(state, value: ITopMessage[]) {
      state.topMessages = Array.isArray(value) ? value : []
    },

    addTopMessage(state, value: ITopMessage) {
      if (value.id) {
        const existingMessage = state.topMessages.find(item => item.id == value.id)
        if (!existingMessage) {
          state.topMessages.unshift(value)
        }
      }
    },

    setCurrentScreen(state, value) {
      state.currentScreen = value
    },

    setStorageLimitReached(state, value) {
      state.storageLimitReached = value
    },

    setUsersLimitReached(state, value) {
      state.usersLimitReached = value
    },

    setForceAllowUnload(state, value) {
      state.forceAllowUnload = value
    },

    setSeeker(state, data: ISeeker) {
      if (data?.type) {
        const seeker = state.seekers.find(item => item.type === data.type)
        if (seeker) {
          Object.assign(seeker, data)
        } else {
          state.seekers.push(data)
        }
      }
    },

    setNeedShowSmileFeedback(state, value) {
      state.needShowSmileFeedback = !!value
    },

    setNoOtherUsers(state, value) {
      state.noOtherUsers = !!value
    },

    setNoTags(state, value) {
      state.noTags = !!value
    }

    // setActiveFormForPasteUpload(state, value: string) {
    //   state.activeFormForPasteUpload = value
    // },
    // setHtmlTitle(state, title: string) {
    //   state.htmlTitle = title
    // },
    // setNoteFormId(state, value: number) {
    //   state.noteFormId = value
    // },
  },

  actions: {

    // setProjectTab({commit}, tabName:string) {
    //   commit('setProjectTab', tabName)
    // },

    addNotification({commit, getters, dispatch}, notification: Partial<INotification>, options: any = {}) {
      const item: INotification = {
        id: _uniqueId(),
        text: '',
        type: 'message',
        showTime: 3000,
      }
      if (options.showTime) {
        item.showTime = options.showTime
      }
      options = Object.assign(options, {
        showTimeMax: 6000,
        increaseShowTimeAfterTextLength: 50,
      })
      Object.assign(item, notification)
      if (item.text) {

        const existingNotificationWithSameId = getters.getNotificationById(item.id)
        if (existingNotificationWithSameId) {
          dispatch('removeNotification', item.id)
        }

        if (item.text.length > options.increaseShowTimeAfterTextLength) {
          //const textLengthRatio = (item.text.length/options.increaseShowTimeAfterTextLength) + 1
          const textLengthRatio = item.text.length/options.increaseShowTimeAfterTextLength
          item.showTime = Math.min(item.showTime * textLengthRatio, options.showTimeMax)
        }
        const testElement = document.createElement('div')
        testElement.innerHTML = item.text
        const textContainsLink = !!testElement.getElementsByTagName('a').length
        if (item.showTime < options.showTimeMax && textContainsLink) {
          item.showTime = options.showTimeMax
        }
        commit('addNotification', item);
        if (item.showTime) {
          item.hideTimer = setTimeout(() => {
            commit('removeNotification', item.id);
          }, item.showTime)
        }
      }
    },

    removeNotification({commit, state}, id: string) {
      const notification: INotification | undefined = state.notifications.find(item => item.id === id)
      console.log('removeNotification', notification?.hideTimer, notification)
      if (notification?.hideTimer) {
        clearTimeout(notification.hideTimer)
      }
      commit('removeNotification', id);
    },

    addConfirm({commit}, confirm: Partial<IConfirm>) {
      return new Promise((resolve, reject) => {
        const id = _uniqueId()
        const item: IConfirm = {
          id,
          text: '',
          type: 'confirm',
          ok: () => {
            console.log('confirm ok')
            commit('removeConfirm', id);
            resolve(true)
          },
          cancel: () => {
            console.log('confirm cancel')
            commit('removeConfirm', id);
            resolve(false)
          },
        }

        Object.assign(item, confirm)

        if (item.text) {
          commit('addConfirm', item)
        }
      })
    },

    addLoader({commit}, data: IData) {
      commit('removeLoader', data);
      commit('addLoader', data);
    },

    updateTicketGlobally({commit, dispatch}, {id, attrs}) {
      commit('board/updateTicket', {id, attrs})
      commit('listview/updateTicket', {id, attrs})
      commit('dashboard/updateTicket', {id, attrs})
      if (Array.isArray(attrs.team)) {
        dispatch('board/update_ticketTeam', {id, team: attrs.team})
      }
    },

    removeTicketGlobally({commit, dispatch}, id) {
      dispatch('board/removeTicket', id)
      commit('listview/removeTicket', id)
      commit('dashboard/removeTicket', id)
    },

  },

  getters: {
    theme: state => state.theme,
    lastUpdated: state => state.lastUpdated,
    highlightLastUpdated: state => state.highlightLastUpdated,
    demoDomain: state => state.demoDomain,
    trialDomain: state => state.trialDomain,
    trialLeftTime: state => state.trialLeftTime,
    trialTimeProgress: state => state.trialTimeProgress,
    navbarOpened: state => state.navbarOpened,
    companyURL: state => state.companyURL,
    project: state => state.currentProject,
    projects: state => state.projects,
    projectsSeekerActiveFilter: state => state.projectsSeekerActiveFilter,
    projectsCount: state => {return state.projects.length },
    usersForFilter: state => state.usersForFilter,
    tags: state => state.tags,
    tagsByNames: state => state.tagsByNames,
    projectTab: state => state.projectTab,
    settingsTab: state => state.settingsTab,
    notifications: state => state.notifications,
    getNotificationById: (state) => (id: string) => state.notifications.find(item => item.id === id),
    confirms: state => state.confirms,
    loaders: state => state.loaders,
    topMessages: state => state.topMessages,
    currentScreen: state => state.currentScreen,
    storageLimitReached: state => state.storageLimitReached,
    usersLimitReached: state => state.usersLimitReached,
    forceAllowUnload: state => state.forceAllowUnload,
    getSeeker: (state) => (type: string) => state.seekers.find(item => item.type === type),
    needShowSmileFeedback: state => state.needShowSmileFeedback,
    noOtherUsers: state => state.noOtherUsers,
    noTags: state => state.noTags,
    // activeFormForPasteUpload: state => state.activeFormForPasteUpload,
    // htmlTitle: state => state.htmlTitle,
    // noteFormId: state => state.noteFormId,
  },

  modules: {
    auth,
    ticket,
    people,
    settings,
    dashboard,
    board,
    listview,
    report_general,
    project_info,
    project_team,
    ticket_designer,
    workflow_designer,
    automation,
    ticketsfilters,
    dev,
    autorefresh,
    account,
  }
})
