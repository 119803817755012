import {IApiResponse, IData, ITicketTask} from "@/types"
import ApiService from "@/services/api_service"
import qs from "qs"

export default {
  getMyAccount(cancelTokenId?: string): Promise<IApiResponse> {
    return new Promise((resolve, reject) => {
      const actionName = 'edit_account'

      const params: IData = {
        action: actionName,
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId).then((data) => {
        console.log('AccountService getMyAccount success', data)
        resolve(data)
      }).catch((data) => {
        console.log('AccountService getMyAccount error', data)
        reject(data)
      })
    })

  },

  saveBillingAddress(data: IData, cancelTokenId?: string): Promise<IApiResponse> {
    return new Promise((resolve, reject) => {
      const actionName = 'save_billing_addr'

      const params: IData = {
        ...data,
        action: actionName,
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId).then((data) => {
        console.log('AccountService saveBillingAddress success', data)
        resolve(data)
      }).catch((data) => {
        console.log('AccountService saveBillingAddress error', data)
        reject(data)
      })
    })

  },

  cardPay(data: IData, cancelTokenId?: string): Promise<IApiResponse> {
    return new Promise((resolve, reject) => {
      const actionName = 'card_pay'

      const params: IData = {
        ...data,
        action: actionName,
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId).then((data) => {
        console.log('AccountService cardPay success', data)
        resolve(data)
      }).catch((data) => {
        console.log('AccountService cardPay error', data)
        reject(data)
      })
    })

  },

  getCardInfo(data: IData, cancelTokenId?: string): Promise<IApiResponse> {
    return this.cardPay(data, cancelTokenId)
  },

  getExtraCardInfo(cancelTokenId?: string): Promise<IApiResponse> {
    return new Promise((resolve, reject) => {
      const actionName = 'get_card_info'

      const params: IData = {
        action: actionName,
      }

      ApiService.request({
        method: "get",
        params,
      }, cancelTokenId).then((data) => {
        console.log('AccountService getExtraCardInfo success', data)
        resolve(data)
      }).catch((data) => {
        console.log('AccountService getExtraCardInfo error', data)
        reject(data)
      })
    })

  },

  getCancelInfo(cancelTokenId?: string): Promise<IApiResponse> {
    return new Promise((resolve, reject) => {
      const actionName = 'cancel_account'

      const params: IData = {
        action: actionName,
      }

      ApiService.request({
        method: "get",
        params,
      }, cancelTokenId).then((data) => {
        console.log('AccountService getCancelInfo success', data)
        resolve(data)
      }).catch((data) => {
        console.log('AccountService getCancelInfo error', data)
        reject(data)
      })
    })

  },

  cancelSubscription(cancelTokenId?: string): Promise<IApiResponse> {
    return new Promise((resolve, reject) => {
      const actionName = 'cancel_account'

      const params: IData = {
        action: actionName,
        cancel:1
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId).then((data) => {
        console.log('AccountService cancelSubscription success', data)
        resolve(data)
      }).catch((data) => {
        console.log('AccountService cancelSubscription error', data)
        reject(data)
      })
    })

  },


}
