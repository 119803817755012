import ApiService from "@/services/api_service"
import {IApiResponse, IData} from "@/types"
import qs from "qs"

const SettingsService = {

  getCustomEmail(cancelTokenId?: string, options = {}): Promise<IApiResponse> {

    return new Promise((resolve, reject) => {
      const actionName = 'custom_email'

      const params: IData = {
        action: actionName,
      }

      ApiService.request({
        method: "get",
        params,
      }, cancelTokenId, options).then((data) => {
        console.log('SettingsService getCustomEmail success', data)
        resolve(data)
      }).catch((data) => {
        console.log('SettingsService getCustomEmail error', data)
        reject(data)
      })
    })
  },

  setCustomEmail(header: string, footer: string, cancelTokenId?: string, options = {}): Promise<IApiResponse> {

    return new Promise((resolve, reject) => {
      const actionName = 'custom_email'

      const params: IData = {
        action: actionName,
        save: 1,
        header,
        footer,
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId, options).then((data) => {
        console.log('SettingsService setCustomEmail success', data)
        resolve(data)
      }).catch((data) => {
        console.log('SettingsService setCustomEmail error', data)
        reject(data)
      })
    })
  },

  getCustomWelcomeMsg(cancelTokenId?: string, options = {}): Promise<IApiResponse> {

    return new Promise((resolve, reject) => {
      const actionName = 'custom_msg'

      const params: IData = {
        action: actionName,
      }

      ApiService.request({
        method: "get",
        params,
      }, cancelTokenId, options).then((data) => {
        console.log('SettingsService getCustomWelcomeMsg success', data)
        resolve(data)
      }).catch((data) => {
        console.log('SettingsService getCustomWelcomeMsg error', data)
        reject(data)
      })
    })
  },

  setCustomWelcomeMsg(msg: string, cancelTokenId?: string, options = {}): Promise<IApiResponse> {

    return new Promise((resolve, reject) => {
      const actionName = 'custom_msg'

      const params: IData = {
        action: actionName,
        save: 1,
        msg,
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId, options).then((data) => {
        console.log('SettingsService setCustomMsg success', data)
        resolve(data)
      }).catch((data) => {
        console.log('SettingsService setCustomMsg error', data)
        reject(data)
      })
    })
  },

  getThemeSettings(cancelTokenId?: string, options = {}): Promise<IApiResponse> {

    return new Promise((resolve, reject) => {
      const actionName = 'edit_themes'

      const params: IData = {
        action: actionName,
      }

      ApiService.request({
        method: "get",
        params,
      }, cancelTokenId, options).then((data) => {
        console.log('SettingsService getThemeSettings success', data)
        resolve(data)
      }).catch((data) => {
        console.log('SettingsService getThemeSettings error', data)
        reject(data)
      })
    })
  },

  saveThemeSettings(data: IData, cancelTokenId?: string, options = {}): Promise<IApiResponse> {

    return new Promise((resolve, reject) => {
      const actionName = 'save_themes'

      const params: IData = Object.assign(data, {
        action: actionName,
      })

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId, options).then((data) => {
        console.log('SettingsService saveThemeSettings success', data)
        resolve(data)
      }).catch((data) => {
        console.log('SettingsService saveThemeSettings error', data)
        reject(data)
      })
    })

  },


  getCustomLogo(cancelTokenId?: string, options = {}): Promise<IApiResponse> {

    return new Promise((resolve, reject) => {
      const actionName = 'custom_logo'

      const params: IData = {
        action: actionName,
      }

      ApiService.request({
        method: "get",
        params,
      }, cancelTokenId, options).then((data) => {
        console.log('SettingsService getCustomLogo success', data)
        resolve(data)
      }).catch((data) => {
        console.log('SettingsService getCustomLogo error', data)
        reject(data)
      })
    })
  },

  makeLogosPreview(data: IData, cancelTokenId?: string, options = {}): Promise<IApiResponse> {

    return new Promise((resolve, reject) => {
      const actionName = 'custom_logo'

      const params: IData = Object.assign(data, {
        action: actionName,
        sub_action: 'convert',
        make_preview: 'Y',
        _orig_cb_same_logo: 'Y',
        cb_same_logo: 'on',

        // remove_login_logo: 'N',
        // remove_main_logo: 'N',
        // remove_favicon: 'N',
        // OK_submit: 'N',
        // _orig_cb_white_bg: 'N',
        // linkTo: '',
      })

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId, options).then((data) => {
        console.log('SettingsService makeLogosPreview success', data)
        resolve(data)
      }).catch((data) => {
        console.log('SettingsService makeLogosPreview error', data)
        reject(data)
      })
    })
  },

  checkLogoFileExists(fileName: string, cancelTokenId?: string, options = {}): Promise<IApiResponse> {

    return new Promise((resolve, reject) => {
      const actionName = 'custom_logo'

      const params: IData = {
        action: actionName,
        sub_action: 'file_exists',
        file_name: fileName,
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId, options).then((data) => {
        console.log('SettingsService checkLogoFileExists success', data)
        resolve(data)
      }).catch((data) => {
        console.log('SettingsService checkLogoFileExists error', data)
        reject(data)
      })
    })
  },

  toggleLogoBackground(isBackgroundOn: boolean, cancelTokenId?: string, options = {}): Promise<IApiResponse> {

    return new Promise((resolve, reject) => {
      const actionName = 'custom_logo'

      const params: IData = {
        action: actionName,
        sub_action: 'convert',
        make_preview: 'Y',
        _orig_cb_same_logo: 'Y',
        cb_same_logo: 'on',
        cb_white_bg: isBackgroundOn ? 'on' : 'off',
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId, options).then((data) => {
        console.log('SettingsService toggleLogoBackground success', data)
        resolve(data)
      }).catch((data) => {
        console.log('SettingsService toggleLogoBackground error', data)
        reject(data)
      })
    })
  },

  deleteLogoFavicon(type: string, cancelTokenId?: string, options = {}): Promise<IApiResponse> {

    return new Promise((resolve, reject) => {
      const actionName = 'custom_logo'

      const params: IData = {
        action: actionName,
        sub_action: 'process_files',
        //files_ext_posted: 1, //AVB: этот параметр нигде не используется
        del_files : 1,
        type,
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId, options).then((data) => {
        console.log('SettingsService deleteLogoFavicon success', data)
        resolve(data)
      }).catch((data) => {
        console.log('SettingsService deleteLogoFavicon error', data)
        reject(data)
      })
    })

  },

  saveLogoFavicon(data: IData, cancelTokenId?: string, options = {}): Promise<IApiResponse> {

    return new Promise((resolve, reject) => {
      const actionName = 'custom_logo'

      const params: IData = Object.assign(data, {
        action: actionName,
        OK_submit: 'Y',
        sub_action: 'convert',
        _orig_cb_same_logo: 'Y',
        cb_same_logo: 'on',
        make_preview: 'N',
        // remove_login_logo: 'N',
        // remove_main_logo: 'N',
        // remove_favicon: 'N',
      })

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId, options).then((data) => {
        console.log('SettingsService saveLogoFavicon success', data)
        resolve(data)
      }).catch((data) => {
        console.log('SettingsService saveLogoFavicon error', data)
        reject(data)
      })
    })

  },

  deleteProvider(type: string, cancelTokenId?: string, options = {}): Promise<IApiResponse> {

    return new Promise((resolve, reject) => {
      const actionName = 'del_provider'

      const params: IData = {
        action: actionName,
        type_provider: type,
      }

      ApiService.request({
        method: "post",
        data: qs.stringify(params),
      }, cancelTokenId, options).then((data) => {
        console.log('SettingsService deleteProvider success', data)
        resolve(data)
      }).catch((data) => {
        console.log('SettingsService deleteProvider error', data)
        reject(data)
      })
    })

  },

}

export default SettingsService
