import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, vShow as _vShow, withModifiers as _withModifiers, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d77969a6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toolbar hide-scroll flex gap-20 relative" }
const _hoisted_2 = { class: "toolbar-sticky sticky left-0 flex gap-15" }
const _hoisted_3 = ["href", "onClick"]
const _hoisted_4 = { class: "btn-add ml-4" }
const _hoisted_5 = ["placeholder", "value"]
const _hoisted_6 = { class: "toolbar-item-wrap gap-10" }
const _hoisted_7 = { class: "toolbar-item-title font-13" }
const _hoisted_8 = ["data-id"]
const _hoisted_9 = { class: "filter-select-icon" }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { class: "filter-select-arrow" }
const _hoisted_12 = { class: "toolbar-item-wrap gap-10" }
const _hoisted_13 = { class: "toolbar-item-title font-13" }
const _hoisted_14 = ["data-id"]
const _hoisted_15 = { class: "filter-select-icon" }
const _hoisted_16 = ["innerHTML"]
const _hoisted_17 = { class: "filter-select-arrow" }
const _hoisted_18 = { class: "toolbar-item-wrap font-13 gap-10" }
const _hoisted_19 = { class: "toolbar-item-title font-13" }
const _hoisted_20 = { class: "filter-select-icon" }
const _hoisted_21 = ["innerHTML"]
const _hoisted_22 = { class: "filter-select-arrow" }
const _hoisted_23 = { class: "toolbar-right gap-10 flex ml-auto items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_user_avatar = _resolveComponent("user-avatar")!
  const _component_seeker_users = _resolveComponent("seeker-users")!
  const _component_seeker_tags = _resolveComponent("seeker-tags")!
  const _component_search_extra_options = _resolveComponent("search-extra-options")!
  const _component_the_custom_fields_selector = _resolveComponent("the-custom-fields-selector")!
  const _component_seeker_projects = _resolveComponent("seeker-projects")!
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_link, {
          to: {name: 'Ticket', params: {id: 'new'}},
          custom: ""
        }, {
          default: _withCtx(({ href, navigate }) => [
            _createElementVNode("a", {
              ref: "refAddTicketButton",
              href: href,
              class: _normalizeClass({'opacity-50 pointer-events-none ': !_ctx.canAddTicket}),
              onClick: ($event: any) => (_ctx.addTicketClickHandler($event, navigate))
            }, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_svg_icon, { name: 'plus' })
              ])
            ], 10, _hoisted_3)
          ]),
          _: 1
        }),
        _createElementVNode("div", {
          ref: "refSearchExtraTo",
          class: _normalizeClass(["input form-size-1 rounded-full flex items-center search-wrap select-none", {
          selected: _ctx.textFilter
        }])
        }, [
          _createVNode(_component_svg_icon, {
            name: 'search',
            width: "15"
          }),
          _createElementVNode("input", {
            ref: "refSearchTextInput",
            type: "text",
            placeholder: _ctx.t('Search...'),
            value: _ctx.textFilter,
            onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateTextFilter && _ctx.updateTextFilter(...args))),
            class: "transparent select-none"
          }, null, 40, _hoisted_5),
          _withDirectives(_createElementVNode("a", {
            href: "javascript:void(0)",
            action: "reset",
            onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.updateTextFilter && _ctx.updateTextFilter(...args)), ["prevent"])),
            class: "empty-search"
          }, [
            _createVNode(_component_svg_icon, {
              name: 'plus',
              width: "19"
            })
          ], 512), [
            [_vShow, _ctx.textFilter]
          ]),
          _createElementVNode("button", {
            class: "btn-transparent",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.triggerHandlerSearchExtraPopup && _ctx.triggerHandlerSearchExtraPopup(...args)))
          }, [
            _createVNode(_component_svg_icon, {
              name: 'arrow',
              width: "6"
            })
          ])
        ], 2)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.t('requested by')) + ":", 1),
        _createElementVNode("div", {
          ref: "refRequestedBy",
          class: _normalizeClass(["select filter-select rounded-full gap-6", {
          selected: _ctx.requestedBy.id !== '0'
        }]),
          "data-id": _ctx.requestedBy.id,
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.triggerHandlerSeekerRequestedBy && _ctx.triggerHandlerSeekerRequestedBy(...args)))
        }, [
          _createElementVNode("div", _hoisted_9, [
            (_ctx.requestedBy.type === 'virtual')
              ? (_openBlock(), _createBlock(_component_svg_icon, {
                  key: 0,
                  name: 'user',
                  width: "14"
                }))
              : (_openBlock(), _createBlock(_component_user_avatar, {
                  key: _ctx.requestedBy.id,
                  user: _ctx.requestedBy.avatar_data,
                  size: "24"
                }, null, 8, ["user"]))
          ]),
          _createElementVNode("div", {
            class: "filter-select-title flex-1",
            innerHTML: _ctx.requestedBy.name
          }, null, 8, _hoisted_10),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_svg_icon, {
              name: 'arrow',
              width: "6"
            })
          ])
        ], 10, _hoisted_8)
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.t('assigned to')) + ":", 1),
        _createElementVNode("div", {
          ref: "refAssignedTo",
          class: _normalizeClass(["select filter-select rounded-full gap-6", {
          selected: _ctx.assignedTo.id !== '0'
        }]),
          "data-id": _ctx.assignedTo.id,
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.triggerHandlerSeekerAssignedTo && _ctx.triggerHandlerSeekerAssignedTo(...args)))
        }, [
          _createElementVNode("div", _hoisted_15, [
            (_ctx.assignedTo.type === 'virtual')
              ? (_openBlock(), _createBlock(_component_svg_icon, {
                  key: 0,
                  name: 'user',
                  width: "14"
                }))
              : (_openBlock(), _createBlock(_component_user_avatar, {
                  key: _ctx.assignedTo.id,
                  user: _ctx.assignedTo.avatar_data,
                  size: "24"
                }, null, 8, ["user"]))
          ]),
          _createElementVNode("div", {
            class: "filter-select-title flex-1",
            innerHTML: _ctx.assignedTo.name
          }, null, 8, _hoisted_16),
          _createElementVNode("div", _hoisted_17, [
            _createVNode(_component_svg_icon, {
              name: 'arrow',
              width: "6"
            })
          ])
        ], 10, _hoisted_14)
      ]),
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.t('tags')) + ":", 1),
        _createElementVNode("div", {
          ref: "refTagsFilter",
          class: _normalizeClass(["select filter-select rounded-full gap-6", {
          selected: _ctx.selectorTagsLabel
        }]),
          onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.triggerHandlerSeekerTags && _ctx.triggerHandlerSeekerTags(...args)))
        }, [
          _createElementVNode("div", _hoisted_20, [
            _createVNode(_component_svg_icon, {
              name: 'tag',
              width: "14"
            })
          ]),
          _createElementVNode("div", {
            class: "filter-select-title flex-1",
            innerHTML: _ctx.selectorTagsLabel || _ctx.t('- any or no tags -')
          }, null, 8, _hoisted_21),
          _withDirectives(_createElementVNode("a", {
            href: "javascript:void(0)",
            onClick: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.clearTagsFilter && _ctx.clearTagsFilter(...args)), ["prevent","stop"])),
            class: "empty-search"
          }, [
            _createVNode(_component_svg_icon, {
              name: 'plus',
              width: "19"
            })
          ], 512), [
            [_vShow, _ctx.selectorTagsLabel]
          ]),
          _createElementVNode("div", _hoisted_22, [
            _createVNode(_component_svg_icon, {
              name: 'arrow',
              width: "6"
            })
          ])
        ], 2)
      ]),
      _createElementVNode("div", _hoisted_23, [
        (_ctx.screen=='board')
          ? _withDirectives((_openBlock(), _createElementBlock("button", {
              key: 0,
              ref: "refCustomFieldsTo",
              class: "btn options-button",
              onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.triggerHandlerCustomFieldsPopup && _ctx.triggerHandlerCustomFieldsPopup(...args)))
            }, [
              _createVNode(_component_svg_icon, {
                name: 'eye',
                width: "17",
                height: "10"
              })
            ])), [
              [_directive_tippy, {
          content: _ctx.t('Custom fields')
        }]
            ])
          : _createCommentVNode("", true),
        _createElementVNode("button", {
          ref: "refMenuButton",
          class: "btn options-button toolbar-menu-button flex-inline-center",
          onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.menuButtonHandler && _ctx.menuButtonHandler(...args)))
        }, [
          _createVNode(_component_svg_icon, {
            name: 'three-dots',
            width: "12",
            height: "10"
          })
        ], 512)
      ])
    ]),
    (_ctx.isExistSeekerRequestedBy)
      ? (_openBlock(), _createBlock(_component_seeker_users, {
          key: 0,
          ref: "refSeekerRequestedBy",
          "position-target": _ctx.refRequestedBy,
          "max-height": 500,
          selected: [_ctx.requestedBy.id],
          type: "requested_by",
          onApply: _ctx.changeRequestedByFilter
        }, null, 8, ["position-target", "selected", "onApply"]))
      : _createCommentVNode("", true),
    (_ctx.isExistSeekerAssignedTo)
      ? (_openBlock(), _createBlock(_component_seeker_users, {
          key: 1,
          ref: "refSeekerAssignedTo",
          "position-target": _ctx.refAssignedTo,
          "max-height": 500,
          selected: [_ctx.assignedTo.id],
          type: "assigned_to",
          onApply: _ctx.changeAssignedToFilter
        }, null, 8, ["position-target", "selected", "onApply"]))
      : _createCommentVNode("", true),
    (_ctx.isExistSeekerTags)
      ? (_openBlock(), _createBlock(_component_seeker_tags, {
          key: 2,
          ref: "refSeekerTags",
          "position-target": _ctx.refTagsFilter,
          "max-height": 500,
          selected: _ctx.selectedTagsIds,
          multiple: true,
          moveSelectedTop: true,
          noTagsOption: true,
          tagsLogicSelector: true,
          toolbarButtonEditTags: true,
          onApply: _ctx.changeTagsFilter
        }, null, 8, ["position-target", "selected", "onApply"]))
      : _createCommentVNode("", true),
    (_ctx.searchFields.length && _ctx.isExistSearchExtraPopup)
      ? (_openBlock(), _createBlock(_component_search_extra_options, {
          key: 3,
          ref: "searchExtraPopup",
          "position-target": _ctx.refSearchExtraTo,
          "search-in": _ctx.searchIn,
          "search-fields": _ctx.searchFields,
          onChangeSearchIn: _ctx.changeSearchInHandler
        }, null, 8, ["position-target", "search-in", "search-fields", "onChangeSearchIn"]))
      : _createCommentVNode("", true),
    (_ctx.isExistCustomFieldsPopup)
      ? (_openBlock(), _createBlock(_component_the_custom_fields_selector, {
          key: 4,
          ref: "customFieldsPopup",
          "position-target": _ctx.refCustomFieldsTo,
          "project-id": _ctx.projectID,
          onApply: _ctx.changeCustomFields
        }, null, 8, ["position-target", "project-id", "onApply"]))
      : _createCommentVNode("", true),
    (_ctx.isExistSeekerProjects)
      ? (_openBlock(), _createBlock(_component_seeker_projects, {
          key: 5,
          ref: "refSeekerProjects",
          type: "add_ticket_in_dashboard",
          "position-target": _ctx.refAddTicketButton,
          "max-height": 500,
          "advanced-open": false,
          onApply: _ctx.addTicketInProject
        }, null, 8, ["position-target", "onApply"]))
      : _createCommentVNode("", true)
  ], 64))
}